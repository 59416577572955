<template>
  <div>
    <HeaderFive>
    </HeaderFive>

 <!-- Start Bradcaump area -->
    <div class="bradcaump_area">
      <div class="paralaxbg--3" data-black-overlay="6">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="text-center bradcaump_inner">
                <h2 class="bradcaump-title">
                  Susisiekti
                </h2>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <!-- End Bradcaump area -->

    <!-- Start Contact Area -->
    <div class="rf-contact-area ">
      <div class="contact-wrapper">
        <v-row align="center">
          <v-col lg="7" md="6" sm="12" cols="12">
            <div class="contact-address">
              <h2 class="heading-title">Pirmininkas</h2>
              Zigmantas Jaunius
              <div
                class="address"
                v-for="(address, i) in addressContentMain"
                :key="i"
              >
                <i class="icon" v-html="iconSvg(address.icon)"></i>
                <div class="content">
                  <p v-if="address.isAddress">
                    {{ address.desc1 }} <br />
                    {{ address.desc2 }}
                  </p>
                  <p v-if="address.isNumber">
                    <a :href="address.to">{{ address.num1 }}</a>
                  </p>
                  <p v-if="address.isNumber">
                    <a :href="address.to">{{ address.num2 }}</a>
                  </p>
                  <p v-if="address.isMail">
                    <a :href="`mailto:${address.to}`">{{ address.mail }}</a>
                  </p>
                </div>
              </div>
            </div>
              <div class="contact-address">
              <h2 class="heading-title">Taryba</h2>
              <div
                class="address"
                v-for="(address, i) in addressContentTeam"
                :key="i"
              >
                <i class="icon" v-html="iconSvg(address.icon)"></i>
                <div class="content">
                  <p v-if="address.isAddress">
                    {{ address.desc1 }} <br />
                    {{ address.desc2 }}
                  </p>
                  <p v-if="address.isNumber">
                    <a :href="address.to">{{ address.num1 }}</a>
                  </p>
                  <p v-if="address.isNumber">
                    <a :href="address.to">{{ address.num2 }}</a>
                  </p>
                  <p v-if="address.isMail">
                    <a :href="`mailto:${address.to}`">{{ address.mail }}</a>
                  </p>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- End Contact Area -->

    <!-- Start Google MAp -->
    <div class="google-map">
      <div class="mapouter">
        <div class="gmap_canvas">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d36190.474910982266!2d21.427031590942835!3d55.464328352055034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e4f22536816d07%3A0xd675db8038606e65!2sSaugos!5e0!3m2!1sen!2slt!4v1637926789826!5m2!1sen!2slt" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>
    </div>
    <!-- End Google MAp -->
    <Footer />
  </div>
</template>

<script>
  import HeaderFive from "../components/header/HeaderFive";
  import Footer from "../components/footer/Footer";
  import feather from "feather-icons";
  export default {
    components: {
      HeaderFive,
      Footer,
    },
    data() {
      return {
        addressContentMain: [
          {
            icon: "map-pin",
            desc1: "Saugų kaimas, LT-99402 Šilutės rajonas",
            isAddress: true,
          },
          {
            icon: "smartphone",
            num1: "+370-682-12533",
            isNumber: true,
          },
          {
            icon: "mail",
            mail: "zigmantas.jaunius@gmail.com ",
            to: "mailto:zigmantas.jaunius@gmail.com ",
            isMail: true,
          },
        ],
        addressContentTeam: [
          {
            icon: "mail",
            desc1: "Daiva Rimkienė, tel. 8-673-47211, el.paštas: daivarimkiene@gmail.com",
            isAddress: true,
          },
            {
            icon: "mail",
            desc1: "Saulius Danilevičius, tel. 8-686-46869, el. paštas: saulensd@gmail.com",
            isAddress: true,
          },
            {
            icon: "mail",
            desc1:   "Eugenijus Judeikis, tel. 8-683-91515, el. pastas: eugenijus@judeikis.lt",
            isAddress: true,
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
      onSubmit() {
        let headers = new Headers();

        headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');

        // TODO: change
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('GET', 'POST', 'OPTIONS');

        var formBody = [];
        formBody.push("name" + "=" + this.formData.name)
        formBody.push("email" + "=" + this.formData.email)
        formBody.push("message" + "=" + this.formData.message)
        formBody = formBody.join("&");

        const requestOptions = {
          method: "POST",
          headers: headers,
          mode: 'no-cors',
          body: formBody,
        };
        console.log(this.formData);
        fetch("https://hooks.gollo.lt/contact", requestOptions)
          .then(
            (response) => {
              this.$router.push({ path: 'aciu' })
              return response.json();
            },
            (error) => {
              console.log(error);
            }          
          );       
      },
    },
  };
</script>

<style lang="scss" scoped>
  .inpur-error,
  .inpur-success {
    display: block;
    margin-top: 5px;
    font-size: 14px;
  }
  .inpur-error {
    color: #f10;
  }
  .gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 800px;
    width: 100%;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .mapouter {
    position: relative;
    width: 100%;
    height: 100%;
  }
</style>
